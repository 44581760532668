import { Controller } from "stimulus"
import Highcharts from "highcharts"
import exportConfig from '../export_config';
require('highcharts/modules/exporting')(Highcharts);

export default class extends Controller {
  connect() {
    let subtitleText;

    if (JSON.parse(this.data.get("series")).length > 1) {
      subtitleText = 'Hover over dates below to see the FIA results for a specific date';
    } else {
      subtitleText = 'Date:';
    }

    let config = {
      colors: ['#f5dfdc', '#f9d643','#70D6D6', '#162359', '#009490'],
      chart: {
        type: 'bar',
        style: {
          fontFamily: 'Montserrat',
          overflow: 'visible' // Set overflow to visible to prevent hiding the download button
        }
      },
      title: {
        text: 'PROPORTION OF ITEMS BY SCORE',
        align: 'left',
        style: {
          color: '#159490',
          fontWeight: 'bold'
        },
      },
      subtitle: {
        text: subtitleText,
        align: 'left',
      },
      xAxis: {
        categories: [
          'Laying the foundation',
          'Installing',
          'Implementing',
          'Sustaining Implementation',
          'Total Score'
        ],
        title: {
          text: null
        },
        labels: {
          style: {
            width: '100px'
          }
        }
      },
      yAxis: {
        min: -0.1,
        max: 100,
        title: {
          text: null
        },
        labels: {
          format: '{value}%'
        },
        startOnTick: false,
      },
      tooltip: {
        valueSuffix: '%'
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          borderColor: "#162359",
          borderWidth: 2,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%'
          },
          colorByPoint: true
        },
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'top',
        floating: false,
        borderWidth: 0,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        symbolPadding: 0,
        symbolWidth: 0,
        symbolHeight: 0,
        squareSymbol: false
      },
      credits: {
        enabled: false
      },
      series: JSON.parse(this.data.get("series")),
      ...exportConfig
    }

    // We are delaying the loading of highcharts here because
    // when the data is cached from a previous load highcharts
    // loads too quickly causing the size of the chart to be incorrect.
    // Delaying the load fixes this.
    setTimeout(() => {
      Highcharts.chart(this.element, config)
    }, 250)
  }
}
