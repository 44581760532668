import { Controller } from "stimulus"
import Highcharts from "highcharts"
import legendScroll from '../legend_scroll';
import exportConfig from '../export_config';
require('highcharts/modules/exporting')(Highcharts);

export default class extends Controller {
  connect() {
    let config = {
      chart: {
        height: 300 * JSON.parse(this.data.get("series")).length,
        type: 'bar',
        style: {
          fontFamily: 'Montserrat',
          overflow: 'visible' // Set overflow to visible to prevent hiding the download button
        }
      },
      title: {
        text: 'Domain Scores',
        align: 'left',
        style: {
          color: '#159490',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: JSON.parse(this.data.get("categories")),
        lineColor: 'white',
      },
      yAxis: {
        min: -0.1,
        max: 100,
        title: {
          text: null
        },
        labels: {
          format: '{value}%',
          style: {
            fontSize: '12px'
          }
        },
        startOnTick: false,
      },
      tooltip: {
        valueSuffix: '%',
        style: {
          fontSize: '12px'
        }
      },
      plotOptions: {
        series: {
          pointPadding: 0.1,
          groupPadding: 0.1,
          shadow: false,
          borderColor: "#162359",
          borderWidth: 2,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}% ({series.name})',
            allowOverlap: true,
            style: {
              fontSize: '12px'
            }
          },
          states: {
            hover: {
              // by default; highcharts set our brightness to 0.1 which was washing out our #f5dfdc (peach)color
              // https://api.highcharts.com/highcharts/plotOptions.column.states.hover.brightness
              // by setting it to 0.0, the color does not wash out
              brightness: 0.0
            }
          }
        },
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        y: 30,
        floating: false,
        borderWidth: 0,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        symbolPadding: 0,
        symbolWidth: 0,
        symbolHeight: 0,
        squareSymbol: false,
        width: 200,
        title: {
          text: "Hover over option below to highlight specific bars on the chart. Click on the items in the list below to hide them from the chart.",
          style: {
            fontWeight: 'normal',
          }
        }
      },
      credits: {
        enabled: false
      },
      series: JSON.parse(this.data.get("series")),
      exporting: {
        enabled: false
      }
    }

    // We are delaying the loading of highcharts here because
    // when the data is cached from a previous load highcharts
    // loads too quickly causing the size of the chart to be incorrect.
    // Delaying the load fixes this.
    setTimeout(() => {
      Highcharts.chart(this.element, config, legendScroll)
    }, 250)
  }
}
