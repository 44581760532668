import { Controller } from "stimulus"
import Highcharts from "highcharts"
import exportConfig from '../export_config';
import legendScroll from '../legend_scroll';
require('highcharts/modules/exporting')(Highcharts);

export default class extends Controller {
  connect() {
    let config = {
      chart: {
        height: (75 * JSON.parse(this.data.get("series")).length),
        type: 'bar',
        style: {
          fontFamily: 'Montserrat',
          overflow: 'visible' // Set overflow to visible to prevent hiding the download button
        }
      },
      title: {
        text: this.data.get("title"),
        align: 'left',
        style: {
          color: '#162359',
          fontWeight: 'bold'
        }
      },
      xAxis: {
        categories: JSON.parse(this.data.get("categories")),
        lineColor: 'white',
      },
      yAxis: {
        min: -0.1,
        max: 100,
        title: {
          text: null
        },
        labels: {
          format: '{value}%',
          style: {
            fontSize: '12px'
          }
        },
        startOnTick: false,
      },
      tooltip: {
        valueSuffix: '%',
        style: {
          fontSize: '12px'
        }
      },
      plotOptions: {
        series: {
          groupPadding: 0.1,
          shadow: false,
          borderColor: "#162359",
          stacking: true,
          borderWidth: 2,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%',
            allowOverlap: true,
            style: {
              fontSize: '12px'
            }
          },
          states: {
            hover: {
              // by default; highcharts set our brightness to 0.1 which was washing out our #f5dfdc (peach)color
              // https://api.highcharts.com/highcharts/plotOptions.column.states.hover.brightness
              // by setting it to 0.0, the color does not wash out
              brightness: 0.0
            }
          },
        },
        bar: {
          dataLabels: {
            inside: false,
            x: 5,
            style: {
              color: "#2F3535"
            }
          },
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        y: 30,
        floating: false,
        borderWidth: 0,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        symbolPadding: 0,
        symbolWidth: 0,
        symbolHeight: 0,
        squareSymbol: false,
        width: 200,
        title: {
          text: "Hover over option below to highlight specific bars on the chart. Click on the items in the list below to hide them from the chart.",
          style: {
            fontWeight: 'normal',
          }
        },
      },
      credits: {
        enabled: false
      },
      series: JSON.parse(this.data.get("series")),
      exporting: {
        enabled: false
      }
    }

    // Because we are treating the x-axis data as individual series, we need to
    // manually configure how the legend items are hidden and shown.
    // https://www.highcharts.com/forum/viewtopic.php?p=114801#p114801
    let originalData = JSON.parse(this.data.get("series"))
    originalData.forEach(series => series.visible = true)
    let originalCategories = JSON.parse(this.data.get("categories")).map(category => ({"name": category, "visible": true}))
    let event = {
      legendItemClick: function (e) {
        let clickedName = e.target.name;

        let data = originalData.find((series) => series.name == clickedName)
        data.visible = !data.visible;
        let category = originalCategories.find((category) => category.name == clickedName)
        category.visible = !category.visible;

        let index = 0
        let currentData = JSON.parse(JSON.stringify(originalData))
        currentData.forEach((series, i) => {
          if (series.visible) {
            series.data[0].x = index
            index++;
          } else {
            series.data = []
          }
        })

        let currentCategories = originalCategories.filter(category => category.visible);

        // Re-render the chart with the current data
        Highcharts.chart(this.chart.renderTo, { ...config, series: currentData, xAxis: { ...config.xAxis, categories: currentCategories } });
        return false; // prevent default behavior
      }
    }

    config['plotOptions']['series']['events'] = event;

    // We are delaying the loading of highcharts here because
    // when the data is cached from a previous load highcharts
    // loads too quickly causing the size of the chart to be incorrect.
    // Delaying the load fixes this.
    setTimeout(() => {
      Highcharts.chart(this.element, config, legendScroll)
    }, 250)
  }
}
